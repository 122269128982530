//
//
//
//
//
//
//
//
//

import Frames from "./Frames"
//tab对应的组件

export default {
  data() {
    return {
    };
  },
  components: {
    Frames,
  },
};
